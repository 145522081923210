const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/index.vue"),
    meta: {
      private: false,
    },
  },
  {
    path: "/",
    redirect: "pages/profile",
    component: () => import("@/layouts/Layout"),
    meta: { private: true },
    children: [
      {
        name: "Profile",
        path: "pages/profile",
        component: () => import("@/views/pages/Profile"),
        meta: { private: true },
      },      

      {
        name: "Users",
        path: "users",
        component: () => import("@/views/Users/index.vue"),
        meta: { private: true },
      },

      {
        name: "Employees",
        path: "employees",
        component: () => import("@/views/Employees/index.vue"),
        meta: { private: true },
      },

      {
        name: "EmployeesNew",
        path: "/employees/new",
        component: () => import("@/views/Employees/New/index.vue"),
        meta: { private: true },
      },

      {
        name: "EmployeesEdit",
        path: "/employees/:id/edit",
        component: () => import("@/views/Employees/Edit/index.vue"),
        meta: { private: true },
        children: [
          {
            path: "/employees/:id/edit",
            name: "EmployeesEditGeneral",
            component: () =>
              import("@/views/Employees/Edit/Pages/General/index.vue"),
            meta: {
              private: true,
            },
          },
          {
            path: "/employees/:id/contracts",
            name: "EmployeesEditContracts",
            component: () =>
              import("@/views/Employees/Edit/Pages/Contracts/index.vue"),
            meta: {
              private: true,
            },
          },
          {
            path: "/employees/:id/orders",
            component: () =>
              import("@/views/Employees/Edit/Pages/Orders/index.vue"),
            name: "EmployeesEditOrders",
            meta: {
              private: true,
            },
          },
          {
            path: "/employees/:id/work-experience-salary",
            name: "EmployeesEditWorkExperienceAndSalary",
            component: () =>
              import(
                "@/views/Employees/Edit/Pages/WorkExperienceAndSalary/index.vue"
              ),
            meta: {
              private: true,
            },
          },
          {
            path: "/employees/:id/vacations",
            component: () =>
              import("@/views/Employees/Edit/Pages/Vacations/index.vue"),
            name: "EmployeesEditVacations",
            meta: {
              private: true,
            },
          },
          {
            path: "/employees/:id/documents",
            component: () =>
              import("@/views/Employees/Edit/Pages/Documents/index.vue"),
            name: "EmployeesEditDocuments",
            meta: {
              private: true,
            },
          },
        ],
      },

      {
        name: "EmployeesView",
        path: "/employees/:id/edit",
        component: () => import("@/views/Employees/View/index.vue"),
        meta: { private: true },
        children: [
          {
            name: "EmployeesViewGeneral",
            path: "/employees/:id/view",
            component: () =>
              import("@/views/Employees/View/Pages/General/index.vue"),
            meta: { private: true },
          },

          {
            name: "EmployeesViewContracts",
            path: "/employees/:id/contracts/view",
            component: () =>
              import("@/views/Employees/View/Pages/Contracts/index.vue"),
            meta: { private: true },
          },

          {
            name: "EmployeesViewOrders",
            path: "/employees/:id/orders/view",
            component: () =>
              import("@/views/Employees/View/Pages/Orders/index.vue"),
            meta: { private: true },
          },

          {
            name: "EmployeesViewVacations",
            path: "/employees/:id/vacations/view",
            component: () =>
              import("@/views/Employees/View/Pages/Vacations/index.vue"),
            meta: { private: true },
          },

          {
            name: "EmployeesViewDocuments",
            path: "/employees/:id/documents/view",
            component: () =>
              import("@/views/Employees/View/Pages/Documents/index.vue"),
            meta: { private: true },
          },

          {
            name: "EmployeeViewWorkExperienceAndSalary",
            path: "/employees/:id/work-experience-salary/view",
            component: () =>
              import(
                "@/views/Employees/View/Pages/WorkExperienceAndSalary/index.vue"
              ),
            meta: { private: true },
          },
        ],
      },

      {
        name: "Roles",
        path: "roles",
        component: () => import("@/views/Roles/index.vue"),
        meta: { private: true },
      },   

      {
        name: "Sizes",
        path: "sizes",
        component: () => import("@/views/Sizes/index.vue"),
        meta: { private: true },
      },

      {
        name: "Subjects",
        path: "subjects",
        component: () => import("@/views/Subjects/index.vue"),
        meta: { private: true },
      },

      {
        name: "StudentCertificates",
        path: "certificates",
        component: () => import("@/views/StudentCertificates/index.vue"),
        meta: { private: true },
      },


      {
        name: "Products",
        path: "products",
        component: () => import("@/views/Products/index.vue"),
        meta: { private: true },
      },

      {
        name: "ProductOrders",
        path: "product-orders",
        component: () => import("@/views/ProductOrders/index.vue"),
        meta: { private: true },
      },

      {
        name: "Students",
        path: "students",
        component: () => import("@/views/Students/index.vue"),
        meta: { private: true },
      },

      {
        name: "StudentsNew",
        path: "/students/new",
        component: () => import("@/views/Students/New/index.vue"),
        meta: { private: true },
      },

      {
        name: "StudentsEdit",
        path: "/students/:id/edit",
        component: () => import("@/views/Students/Edit/index.vue"),
        meta: { private: true },
        children: [
          {
            path: "/students/:id/edit",
            name: "StudentsEditGeneral",
            component: () =>
              import("@/views/Students/Edit/Pages/General/index.vue"),
            meta: {
              private: true,
            },
          },

          {
            path: "/students/:id/contracts",
            name: "StudentsEditContracts",
            component: () =>
              import("@/views/Students/Edit/Pages/Contracts/index.vue"),
            meta: {
              private: true,
            },
          },       
         
          {
            path: "/students/:id/documents",
            component: () =>
              import("@/views/Students/Edit/Pages/Documents/index.vue"),
            name: "StudentsEditDocuments",
            meta: {
              private: true,
            },
          },
        ],
      },

      {
        name: "StudentsView",
        path: "/students/:id/edit",
        component: () => import("@/views/Students/View/index.vue"),
        meta: { private: true },
        children: [
          {
            name: "StudentsViewGeneral",
            path: "/students/:id/view",
            component: () =>
              import("@/views/Students/View/Pages/General/index.vue"),
            meta: { private: true },
          },

          {
            name: "StudentsViewContracts",
            path: "/students/:id/contracts/view",
            component: () =>
              import("@/views/Students/View/Pages/Contracts/index.vue"),
            meta: { private: true },
          },
          
          {
            name: "StudentsViewDocuments",
            path: "/students/:id/documents/view",
            component: () =>
              import("@/views/Students/View/Pages/Documents/index.vue"),
            meta: { private: true },
          }         
        ],
      },
    ],
  },
];

export default routes;
