import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
Vue.use(Vuetify);

const theme = {
  primary: "#047abf",
  info: "#1e88e5",
  success: "#00BCD4",
  green: "#4CAF50",
  accent: "#fc4b6c",
  default: "#563dea",
  red: "#D32F37",
  error: "#E53935",
  "light-red": "#FFCDD2",
  grey: "#424242",
  "light-grey": "#BDBDBD",
  "light-grey2": "#FAFAFA",
  black: "#000000",
  "blue-grey":"#B2CDE3"
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
