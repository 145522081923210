import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const accessToken = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
const refreshToken = localStorage.getItem(
  process.env.VUE_APP_REFRESH_TOKEN_KEY
);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white",
    SidebarBg: "",
    accessToken,
    refreshToken,
    userData: null,
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token;
      localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_KEY, token);
    },
    SET_REFRESH_TOKEN(state, token) {
      state.refreshToken = token;
      localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN_KEY, token);
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData;
      localStorage.setItem(
        process.env.VUE_APP_USER_DATA_KEY,
        JSON.stringify(userData)
      );
    },
    SET_USER_PROFILE_INFO(state, profileInfo) {
      const role = state.userData.role;
      state.userData = {
        ...profileInfo,
        role,
      };
    },

    LOG_OUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.userData = null;
      localStorage.clear();
    },
  },
  actions: {
    setAccessToken({ commit }, accessToken) {
      commit("SET_ACCESS_TOKEN", accessToken);
    },
    setRefreshToken({ commit }, refreshToken) {
      commit("SET_REFRESH_TOKEN", refreshToken);
    },
    updateTokens({ commit }, { refreshToken, accessToken }) {
      commit("SET_ACCESS_TOKEN", accessToken);
      commit("SET_REFRESH_TOKEN", refreshToken);
    },
    setUserData({ commit }, userData) {
      commit("SET_USER_DATA", userData);
    },
    setUserProfileInfo({ commit }, profileInfo) {
      commit("SET_USER_PROFILE_INFO", profileInfo);
    },
    logOut({ commit }) {
      commit("LOG_OUT");
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.accessToken,
    getUserData: (state) => state.userData,
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
    inRole: (state) => (role) => {
      if (state.userData && state.userData.roles)
        var result = state.userData.roles.findIndex((e) => e === role) > -1;
      return result;
    },
  },
});
