import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  if (to.matched.some((route) => route.meta.private)) {
    if (!store.getters.isLoggedIn) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
