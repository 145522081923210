import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./routers";
import store from "./store";
import "./plugins/base";
import { VueMaskDirective } from "v-mask";
export const bus = new Vue();
Vue.use(vuetify);
Vue.directive("mask", VueMaskDirective);
const moment = require("moment");
require("moment/locale/az");

Vue.use(require("vue-moment"), {
  moment,
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
